import React from 'react';
import { Grid, useTheme } from '@mui/material';
import ImageUpload from './ImageUpload';
import ImageListComp from './ImageListComp';
import { isEmpty } from 'lodash';
import { ImageUploadItem, ImageUploadWrapperProps } from './image.types';
import SearchInputComp from '../../coreUI/searchComp/SearchInputComp';

const ImageUploadCompWrapper = ({
  emptyStateValueImageUpload,
  searchUploadedImages,
  imageList,
  loading,
  langWords,
  addImageUpload,
  setImageUploadList,
  appMessageHandling,
  tempImageList,
  downloadUploadedImage,
  feedbackImageUpload,
  deleteUploadedImage,
  selectedOutputNode,
  uploadedImageList,
  deletedImgId,
  handleInsertImage,
}: ImageUploadWrapperProps) => {
  const theme = useTheme();
  const handleSubmitSearch = (value: string) => {
    if (isEmpty(value)) {
      return emptyStateValueImageUpload({ imageList: null });
    }
    searchUploadedImages(value);
  };

  return (
    <Grid
      item
      container
      xs={12}
      spacing={2}
    >
      <Grid
        item
        xs={12}
      >
        <SearchInputComp
          handleSubmit={handleSubmitSearch}
          loading={loading}
          placeholder={langWords.searchImage}
          searchWord={langWords.searchWord}
        />
      </Grid>
      {!imageList && (
        <Grid
          item
          xs={12}
        >
          <ImageUpload
            tempImageList={tempImageList}
            addImageUpload={addImageUpload}
            setImageUploadList={setImageUploadList}
            appMessageHandling={appMessageHandling}
            langWords={langWords}
            uploadedImageList={uploadedImageList}
          />
        </Grid>
      )}
      {imageList && (
        <Grid
          item
          xs={12}
        >
          {Object.values(imageList).length === 0 ? (
            <div style={{ color: theme.palette.primary.main }}>
              {langWords.noImage}
            </div>
          ) : (
            [
              ...Object.values(imageList?.ownerList ?? {}),
              ...Object.values(imageList?.otherList ?? {}),
            ].map(
              (imageItem: ImageUploadItem) =>
                Object.keys(imageItem.images).length > 0 && (
                  <ImageListComp
                    imageItem={imageItem}
                    key={imageItem.id}
                    downloadUploadedImage={downloadUploadedImage}
                    feedbackImageUpload={feedbackImageUpload}
                    deleteUploadedImage={deleteUploadedImage}
                    handleInsertImage={handleInsertImage}
                    selectedOutputNode={selectedOutputNode}
                    langWords={langWords}
                    deletedImgId={deletedImgId}
                  />
                )
            )
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default ImageUploadCompWrapper;
