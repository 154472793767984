import { useState } from 'react';
import { DataGrid, enUS, deDE, frFR } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { HistorySelector } from 'src/redux/translationHistory/selector';
import PaginationComp from './PaginationComp';
import CustomeToolbar from './CustomeToolbar';
import { columns } from './columns';
import {
  fIlterValueSet,
  updateJobValue,
} from 'src/redux/translationHistory/actions';
import { FilterValue } from 'src/types/transHistory.types';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { emptyStateValueTrans } from 'src/redux/translation/actions';
import { useTheme } from '@mui/material';

type Props = {
  onDeleteEntry: (row: any, alertTrigger: boolean) => void;
  showLang: string;
};

const HistoryTable = ({ onDeleteEntry, showLang }: Props) => {
  const { t, i18n } = useTranslation(['common', 'easy_language', 'A11y'], {
    nsMode: 'fallback',
  });
  const [selectedRow, setSlectedRow] = useState<number>(0);
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const [activeId, setActiveId] = useState('');

  const jobsList = useSelector(
    (state: any) => HistorySelector(state).jobsList,
    shallowEqual
  );

  const filterValue = useSelector(
    (state: any) => HistorySelector(state).filters,
    shallowEqual
  );

  const isFetchingJobs = useSelector(
    (state: any) => HistorySelector(state).loading,
    shallowEqual
  );

  const languageLocaleMapping: { [key: string]: any } = {
    en: enUS,
    de: deDE,
    fr: frFR,
  };

  const handleCellClick = (params: any) => {
    setSlectedRow(params.row.id);
    // if (params.field === 'delete_action') {
    //   onDeleteEntry(params.row, true);
    // }
  };

  const handleConfirmFIlter = (data: FilterValue) => {
    dispatch(fIlterValueSet(data));
  };
  function onFilterChange(): void {
    if (!isEmpty(filterValue?.value) || !isEmpty(filterValue?.actualValue)) {
      dispatch(
        fIlterValueSet({
          field: '',
          value: '',
          operation: '',
        })
      );
    }
  }

  const handleClearFilter = () => {
    if (!isEmpty(filterValue?.value) && !isEmpty(filterValue?.actualValue)) {
      dispatch(
        fIlterValueSet({
          ...filterValue,
          value: '',
          actualValue: '',
        })
      );
    }
  };

  const handleSetNewJob = (row: any) => {
    dispatch(updateJobValue(row));
    dispatch(emptyStateValueTrans({ transjob: { jobId: '-1' } }));
    navigate('/easy-language');
  };

  return (
    <div
      style={{ width: '100%', backgroundColor: theme.palette.general.white }}
      aria-label={t('history_table')}
    >
      <DataGrid
        localeText={
          languageLocaleMapping[i18n.language].components.MuiDataGrid
            .defaultProps.localeText
        }
        // autoHeight
        rows={Object.values(jobsList)
          ?.reverse()
          .filter((item: any) =>
            ['', 'none'].includes(showLang) ? true : item.job_task === showLang
          )}
        autoHeight
        columns={columns(
          activeId,
          setActiveId,
          filterValue,
          handleConfirmFIlter,
          t,
          handleSetNewJob,
          onDeleteEntry,
          selectedRow
        )}
        rowHeight={250}
        getRowHeight={(row) => {
          if (row.id === activeId) {
            return 520;
          } else {
            return null;
          }
        }}
        onRowClick={(params: any, event: any) => {
          if (!event.ignore) {
            setActiveId(params.row.id);
          }
        }}
        onCellClick={handleCellClick}
        // initialState={{
        //   pagination: {
        //     paginationModel: { pageSize: Number(entries), page: currentPage },
        //   },
        // }}
        sx={{
          cursor: 'pointer',
          overflowX: 'auto',
          overflowY: 'auto',
          '& .MuiDataGrid-cell--editing': {
            boxShadow: 'none !important',
          },
          '& .MuiDataGrid-cell': {
            justifyContent: 'space-around',
            height: 'fit-content',
          },
          '& .Mui-selected .MuiDataGrid-cell': {
            height: 'auto',
          },
          '& .MuiButtonBase-root': {
            color: theme.palette.primary.main,
          },
          '& .MuiDataGrid-columnSeparator': {
            opacity: '0 !important',
          },
        }}
        onCellKeyDown={(params, e, details) => {
          e.stopPropagation();
        }}
        paginationMode="server"
        sortingMode="server"
        filterMode="server"
        slots={{
          pagination: PaginationComp,
          toolbar: CustomeToolbar,
        }}
        slotProps={{
          filterPanel: {
            filterFormProps: {
              deleteIconProps: {
                onClick: handleClearFilter,
              },
            },
          },
        }}
        loading={isFetchingJobs}
        disableColumnMenu
        onFilterModelChange={onFilterChange}
        autoPageSize={true}
      />
    </div>
  );
};

export default HistoryTable;
