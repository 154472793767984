import TextField from '@mui/material/TextField';
import React, { FC, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Autocomplete, Grid, useTheme } from '@mui/material';
import { isEmpty } from 'lodash';

type SearchInputCompProps = {
  handleSubmit: (value: string) => void;
  loading: boolean;
  placeholder?: string;
  inputId?: string;
  buttonId?: string;
  searchWord: string;
  wrapperStyle?: object;
};

const SearchInputComp: FC<SearchInputCompProps> = ({
  handleSubmit,
  loading,
  placeholder,
  inputId = '',
  buttonId = '',
  searchWord,
  wrapperStyle,
}) => {
  const theme = useTheme();
  const [value, setValue] = useState<string>('');
  const handleChange = (
    event: React.SyntheticEvent,
    value: string,
    reason: 'input' | 'reset' | 'clear'
  ) => {
    setValue(value);
    if (reason === 'clear' || isEmpty(value)) {
      handleSubmit('');
    }
  };
  return (
    <Grid
      item
      container
      xs={12}
      justifyContent={'space-between'}
      onKeyDown={(e) => {
        if (e.key === 'Enter' && !isEmpty(value)) {
          handleSubmit(value);
        }
      }}
      sx={wrapperStyle}
    >
      <Grid
        item
        xs={7.7}
      >
        <Autocomplete
          id={inputId}
          freeSolo={true}
          renderInput={(params) => (
            <TextField
              {...params}
              label={placeholder}
              size="small"
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: `${theme.palette.primary.main} !important`,
                },
                '& .MuiInputLabel-root': {
                  color: `${theme.palette.primary.main} !important`,
                },
              }}
            />
          )}
          autoComplete={false}
          inputValue={value}
          options={[]}
          onInputChange={handleChange}
          disabled={loading}
        />
      </Grid>
      <Grid
        item
        xs={4}
      >
        <LoadingButton
          aria-disabled={isEmpty(value)}
          size="small"
          onClick={() => handleSubmit(value)}
          loading={loading}
          disableElevation
          disableFocusRipple
          variant="contained"
          id={buttonId}
          sx={{
            textTransform: 'none',
            fontSize: '1rem',
            p: '0.375rem 0.75rem',
            width: '100%',
            height: '100%',
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.general.goldenYellow,
            '&:hover': {
              backgroundColor: theme.palette.general.goldenYellow,
            },
            ...(!loading && {
              '&:disabled': {
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.general.goldenYellow,
              },
            }),
          }}
          disabled={isEmpty(value)}
        >
          {searchWord}
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default SearchInputComp;
