import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import UpdateAppPopup from '../../coreUI/updateAppPopup/UpdateAppPopup';
import styles from './LoginForm.module.css';
import { Grid, useTheme } from '@mui/material';
import InputComponent from 'src/coreUI/inputComp/InputComponent';
import PasswordInput from 'src/coreUI/passwordInput/PasswordInput';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { isEmpty } from 'lodash';

type Props = {
  handleSubmit: (e: any) => void;
  showUpdateAppPopup: boolean;
  clearLocalStorage: () => void;
  loginVal: { email: string; password: string };
  handleChange: (e: any) => void;
  // setShowUpdateAppPopup: React.Dispatch<React.SetStateAction<boolean>>
};
const LoginForm = ({
  loginVal,
  handleChange,
  handleSubmit,
  clearLocalStorage,
  showUpdateAppPopup,
}: Props) => {
  const theme = useTheme();

  const { t } = useTranslation(['common', 'account'], {
    nsMode: 'fallback',
  });
  return (
    <Grid
      item
      container
      xs={12}
      spacing={2}
    >
      <Grid
        item
        xs={12}
      >
        <UpdateAppPopup
          show={showUpdateAppPopup}
          onClick={clearLocalStorage}
        />
        <h4 style={{ color: theme.palette.primary.main }}>
          {t('loginForm.loginHeadline')}
        </h4>
      </Grid>
      <Grid
        item
        container
        xs={5.5}
        spacing={3}
        sx={{ m: 'auto' }}
      >
        <Grid
          item
          xs={12}
        >
          <InputComponent
            value={loginVal.email}
            name="email"
            handleChange={handleChange}
            label={t('account.email')}
            require={true}
            style={{ width: '100%' }}
            type="email"
            id="email"
          />
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            textAlign: 'left',
          }}
        >
          <PasswordInput
            value={loginVal.password}
            name="password"
            handleChange={handleChange}
            label={t('signupForm.password')}
            style={{ width: '100%', m: 0 }}
            size="small"
            id="password"
          />
          <Link
            className={`${styles['forgot-password']} linkStyle`}
            to="/reset-password"
            style={{ color: theme.palette.primary.main }}
          >
            {t('loginForm.forgotPassword')}
          </Link>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <LoadingButton
            loadingIndicator={t('translationbutton.loading')}
            type="submit"
            fullWidth
            variant="contained"
            disabled={isEmpty(loginVal.email) || isEmpty(loginVal.password)}
            sx={{
              textTransform: 'capitalize',
              p: '8px 3em',

              '&:disabled': {
                color: theme.palette.secondary.main,
                backgroundColor: theme.palette.primary.main,
              },
            }}
            id="otp-submit"
            onClick={handleSubmit}
          >
            {t('loginForm.loginButton')}
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LoginForm;
