import React, { useState, useRef, useEffect } from 'react';
import TextareaField from './TextareaField';

import SidebarDrawerComp from '../sidebarDrawerComp/SidebarDrawerComp';
import { useSelector, shallowEqual } from 'react-redux';
import { HistorySelector } from 'src/redux/translationHistory/selector';

const Home = () => {
  const [open, setOpen] = useState(false);

  const [outputText, setOutputText] = useState('');
  const [inputText, setInputText] = useState('');
  let inputTextValue = useRef({ text: '' });
  let outputTextValue = useRef({ text: '' });

  const historyJob = useSelector(
    (state: any) => HistorySelector(state).historyJob,
    shallowEqual
  );

  useEffect(() => {
    if (historyJob && inputTextValue) {
      inputTextValue.current.text = historyJob.originaltext;
    }
  }, [historyJob]);

  // UI
  return (
    <>
      <SidebarDrawerComp
        pageBody={
          <TextareaField
            setOutputText={setOutputText}
            inputText={inputText}
            setInputText={setInputText}
            outputText={outputText}
            inputTextValue={inputTextValue}
            outputTextValue={outputTextValue}
            setOpen={setOpen}
            open={open}
          />
        }
        inputText={inputTextValue.current.text}
        outputText={outputTextValue.current.text}
        open={open}
        setOpen={setOpen}
        outputTextValue={undefined}
      />
    </>
  );
};

export default Home;
