import { Permission } from 'src/enums/permission';
import { permissionUtil } from 'src/utils/permissionsUtils';
import { UserReducers } from './selector';
import {
  CREATE_USER_API_KEY,
  DELETE_USER_API_KEY,
  GET_USER_API_KEY,
  GET_USER_DETAILS,
  LOADING,
  NEW_USER_SIGNUP,
  REST_CREATED_API_KEY,
  STOP_LOADING,
  UPDATE_QUOTA_LIMIT,
  UPDATE_QUOTA_SPENT,
  UPDATE_SEPARATOR_STYLE,
  UPDATE_USER_API_KEY,
  UPDATE_USER_DETAILS,
  UPDATE_USER_DETAILS_API,
  USER_EXIST_CHECK,
} from './types';

const initialState: UserReducers = {
  userDetails: null,
  loading: false,
  apiKeys: null,
  createdKey: null,
  hasGroup: false,
  userPermissionsEasy: false,
  userPermissionsPlain: false,
  userExist: false,
};

const checkPermission: (payload: { permissions: Permission[] }) => {
  userPermissionsEasy: boolean;
  userPermissionsPlain: boolean;
} = (payload) => {
  let userPermissionsEasy = permissionUtil(
    [Permission.can_translate_easy],
    payload?.permissions
  );

  let userPermissionsPlain = permissionUtil(
    [Permission.can_translate_plain],
    payload?.permissions
  );

  return { userPermissionsEasy, userPermissionsPlain };
};

export default function alertReducer(
  state = initialState,
  action: { type: any; payload: any }
) {
  const { type, payload } = action;
  switch (type) {
    case GET_USER_DETAILS:
      let { userPermissionsEasy, userPermissionsPlain } =
        checkPermission(payload);
      return {
        ...state,
        userDetails: payload,
        hasGroup: userPermissionsEasy || userPermissionsPlain,
        userPermissionsEasy,
        userPermissionsPlain,
      };
    case CREATE_USER_API_KEY:
      let createdKey = payload.key;
      delete payload.key;
      return {
        ...state,
        createdKey,
        apiKeys: {
          ...state.apiKeys,
          [payload.prefix]: payload,
        },
      };
    case GET_USER_API_KEY:
      let keys = {};
      payload?.forEach((key: { prefix: string }) => {
        keys = {
          ...keys,
          [key.prefix]: key,
        };
      });
      return {
        ...state,
        apiKeys: keys,
      };
    case UPDATE_USER_API_KEY:
      return {
        ...state,
        apiKeys: { ...state.apiKeys, [payload.prefix]: payload },
      };
    case DELETE_USER_API_KEY:
      let newApiKeys: any = { ...(state?.apiKeys ?? {}) };
      delete newApiKeys[payload];
      return {
        ...state,
        apiKeys: newApiKeys,
      };
    case REST_CREATED_API_KEY:
      return {
        ...state,
        createdKey: null,
      };
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_USER_DETAILS:
      return {
        ...state,
        userDetails: payload,
      };
    case UPDATE_QUOTA_LIMIT:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          quotalimit: payload,
        },
      };
    case UPDATE_QUOTA_SPENT:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          quotaspent: payload,
        },
      };
    case USER_EXIST_CHECK:
      return {
        ...state,
        userExist: payload,
      };
    case NEW_USER_SIGNUP: {
      let { userPermissionsEasy, userPermissionsPlain } =
        checkPermission(payload);
      return {
        ...state,
        userDetails: payload,
        hasGroup: userPermissionsEasy || userPermissionsPlain,
        userPermissionsEasy,
        userPermissionsPlain,
      };
    }
    case UPDATE_SEPARATOR_STYLE:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          ...payload,
        },
      };
    case UPDATE_USER_DETAILS_API:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          ...payload,
        },
      };
    default:
      return state;
  }
}
