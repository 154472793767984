import { createSelector } from 'reselect';
import { Permission } from '../../enums/permission';
import { SeparatorValue } from 'src/types/translation.types';

export interface UserReducers {
  loading: boolean;
  userDetails: null | {
    translatorlicence: boolean;
    currentlicense: string;
    preferred_style: SeparatorValue;
    preferred_style_easy: SeparatorValue;
    preferred_style_plain: SeparatorValue;
    userrole: string;
    appversion: string;
    betauser: boolean;
    hasactivelicence: boolean;
    hasreadwhatsnew: boolean;
    hasindividualquota: boolean;
    premiumuser: boolean;
    quotalimit: number;
    quotaspent: number;
    email: string;
    permissions: Permission[];
    previewFeatures: boolean;
    format_input_switch: boolean;
    format_output_switch: boolean;
    preferred_output_language_type: 'easy' | 'plain';
  };
  apiKeys: null | {};
  createdKey: null | string;
  hasGroup: boolean;
  userPermissionsEasy: boolean;
  userPermissionsPlain: boolean;
  userExist: boolean;
}
interface RootState {
  user: UserReducers;
}

// This used to memorize the selector value
export const UserSelector = createSelector(
  (state: RootState) => state.user,
  (user: UserReducers) => {
    return {
      loading: user.loading,
      userDetails: user.userDetails,
      apiKeys: user.apiKeys,
      createdKey: user.createdKey,
      hasGroup: user.hasGroup,
      userPermissionsEasy: user.userPermissionsEasy,
      userPermissionsPlain: user.userPermissionsPlain,
      userExist: user.userExist,
    };
  }
);
