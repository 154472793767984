import userService from '../../services/user.service';
import { appMessageHandling } from '../../utils/errorHandler';
import {
  CREATE_USER_API_KEY,
  DELETE_USER_API_KEY,
  EMPTY_STATE_VALUE,
  GET_USER_API_KEY,
  GET_USER_DETAILS,
  LOADING,
  // NEW_USER_SIGNUP,
  REST_CREATED_API_KEY,
  STOP_LOADING,
  UPDATE_QUOTA_LIMIT,
  UPDATE_QUOTA_SPENT,
  UPDATE_SEPARATOR_STYLE,
  UPDATE_USER_API_KEY,
  UPDATE_USER_DETAILS,
  UPDATE_USER_DETAILS_API,
  USER_EXIST_CHECK,
  USER_REVIEW,
} from './types';
import Sentry from '../../config/sentryConfig';
import { SeparatorValue } from 'src/types/translation.types';
import { UserReducers } from './selector';
import { updateTokenAction, updateUserAction } from '../supabase/actions';

export const getUserDetails = () => async (dispatch: any, getState: any) => {
  try {
    dispatch(loading());
    const [resDtails, resQuota]: {
      data: { preferredstyle: SeparatorValue };
    }[] = await Promise.all([
      await userService.getUserDetailsApi(),
      await userService.getUserQuotaApi(),
    ]);
    localStorage.removeItem('signup_form');
    dispatch({
      type: GET_USER_DETAILS,
      payload: { ...(resDtails?.data ?? {}), ...(resQuota?.data ?? {}) },
    });
  } catch (e: any) {
    Sentry.captureMessage(`Get User Data Error: ${e}`, 'error');

    // check user already signup or not
    if (['User does not exist'].includes(e.response.data.detail)) {
      dispatch(updateUserExist(e.response.data.detail));
    }
    if (['User did not finish signup'].includes(e.response.data.detail)) {
      localStorage.setItem('signup_form', 'true');
      dispatch(updateUserExist(e.response.data.detail));
      window.location.href = '/signup';
    }
  } finally {
    dispatch(stopLoading());
  }
};

export const createUserApiKey = (name: string) => async (dispatch: any) => {
  try {
    dispatch(loading());
    const res = await userService.createUserKeyApi(name);

    dispatch({
      type: CREATE_USER_API_KEY,
      payload: res.data,
    });
  } catch (e: any) {
    Sentry.captureMessage(`Create User Api Key Error: ${e}`, 'error');
    return appMessageHandling(dispatch, e.response.data.detail, 'error');
  } finally {
    dispatch(stopLoading());
  }
};

export const getUserApiKey = () => async (dispatch: any) => {
  try {
    dispatch(loading());
    const res = await userService.getUserKeyApi();
    dispatch({
      type: GET_USER_API_KEY,
      payload: res.data,
    });
  } catch (e: any) {
    Sentry.captureMessage(`Get User Api Key Error: ${e}`, 'error');
    return appMessageHandling(dispatch, 'error.get_user_details', 'error');
  } finally {
    dispatch(stopLoading());
  }
};

export const deleteUserApiKey = (prefix: string) => async (dispatch: any) => {
  try {
    dispatch(loading());
    await userService.deleteUserKeyApi(prefix);
    dispatch({
      type: DELETE_USER_API_KEY,
      payload: prefix,
    });
  } catch (e: any) {
    Sentry.captureMessage(`Delete User Api Key Error: ${e}`, 'error');
    return appMessageHandling(dispatch, e.response.data.detail, 'error');
  } finally {
    dispatch(stopLoading());
  }
};

export const updateUserApiKey =
  (prefix: string, name: string) => async (dispatch: any) => {
    try {
      dispatch(loading());
      const res = await userService.updateUserKeyApi(prefix, name);

      dispatch({
        type: UPDATE_USER_API_KEY,
        payload: res.data,
      });
    } catch (e: any) {
      Sentry.captureMessage(`Update User Api Key Error: ${e}`, 'error');
      return appMessageHandling(dispatch, e.response.data.detail, 'error');
    } finally {
      dispatch(stopLoading());
    }
  };

export const updateUserInfo =
  (data: {
    hasreadwhatsnew?: boolean;
    format_input_switch?: boolean;
    format_output_switch?: boolean;
    preferred_output_language_type?: 'easy' | 'plain';
  }) =>
  async (dispatch: any) => {
    try {
      dispatch(loading());
      const res = await userService.updateUserDetailsApi(data);
      dispatch({
        type: UPDATE_USER_DETAILS_API,
        payload: res.data,
      });
    } catch (e: any) {
      Sentry.captureMessage(`Update User Info Error: ${e}`, 'error');
      return appMessageHandling(dispatch, e.response.data.detail, 'error');
    } finally {
      dispatch(stopLoading());
    }
  };

export const newUserSignup =
  (data: object, navigation: any) => async (dispatch: any) => {
    try {
      dispatch(loading());
      await userService.newUserSignupApi(data);
      await dispatch(getUserDetails());

      navigation('/dashboard');
    } catch (e: any) {
      dispatch(updateUserAction(null));
      dispatch(updateTokenAction(null));
      localStorage.removeItem('token');
      Sentry.captureMessage(`New user signup Error: ${e}`, 'error');
      return appMessageHandling(dispatch, 'error.notCreatedError', 'error');
    } finally {
      dispatch(stopLoading());
    }
  };

export const userReview = (data: object) => async (dispatch: any) => {
  try {
    dispatch(loading());
    await userService.userReviewApi(data);
    dispatch({
      type: USER_REVIEW,
    });
  } catch (e: any) {
    Sentry.captureMessage(`User Review Error: ${e}`, 'error');
    return appMessageHandling(dispatch, e.response.data.detail, 'error');
  } finally {
    dispatch(stopLoading());
  }
};

export const loading = () => (dispatch: any) => {
  return dispatch({
    type: LOADING,
  });
};

export const stopLoading = () => (dispatch: any) => {
  return dispatch({
    type: STOP_LOADING,
  });
};

// No api call
export const updateUserDetails = (data: any) => (dispatch: any) => {
  return dispatch({
    type: UPDATE_USER_DETAILS,
    payload: data,
  });
};

export const resetCreatedApiKey = () => (dispatch: any) => {
  return dispatch({
    type: REST_CREATED_API_KEY,
  });
};

export const setQuotaLimit = (quota: number) => (dispatch: any) => {
  return dispatch({
    type: UPDATE_QUOTA_LIMIT,
    payload: quota,
  });
};

export const setQuotSpent = (quota: number) => (dispatch: any) => {
  return dispatch({
    type: UPDATE_QUOTA_SPENT,
    payload: quota,
  });
};

export const emptyStateValueUser = (value: UserReducers) => (dispatch: any) => {
  return dispatch({
    type: EMPTY_STATE_VALUE,
    payload: value,
  });
};

export const updateUserExist = (value: string) => (dispatch: any) => {
  return dispatch({
    type: USER_EXIST_CHECK,
    payload: ['User does not exist', 'User did not finish signup'].includes(
      value
    )
      ? false
      : true,
  });
};

export const updateSeparatoeStyle =
  (value: {
    preferredStyle?: SeparatorValue;
    preferredStyleEasy?: SeparatorValue;
    preferredStylePlain?: SeparatorValue;
  }) =>
  (dispatch: any) => {
    return dispatch({
      type: UPDATE_SEPARATOR_STYLE,
      payload: value,
    });
  };
