import React, { useEffect, useMemo, useState } from 'react';
import './GlossaryField.css';
import styles from './GlossaryField.module.css';
import GlossaryInputField from './GlossaryInputField';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import AddNewGlossary from './AddNewGlossary';
import GeneralGlossaryListItem from './GeneralGlossaryListItem';
import GlossaryAlerts from './GlossaryAlerts';
import OwnGlossaryListItem from './OwnGlossaryListItem';
import GlossaryItemAlert from './GlossaryItemAlert';
import { isEmpty } from 'lodash';
import {
  Checkbox,
  Grid,
  OutlinedInput,
  Box,
  ThemeProvider,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { DifficultWords, GlossaryFieldProps } from './glossary.types';
import theme from '../../theme/themeConfig';
const GlossaryField = ({
  noTextSelectedWord,
  aiGeneratedWord,
  maxAILimitWord,
  myGlossaryWord,
  exportLinkWord,
  searchGlossaryWord,
  searchWord,
  fromWord,
  addGlossaryWord,
  supabaseUser,
  createNewGlossary,
  glosseryItems,
  loading,
  unAuthAlert,
  AIWordExplanation,
  glossaryTags,
  selectedTagsList,
  difficultWords,
  difficultWordsToShow,
  otherWordExplanation,
  setSelectedTagsList,
  emptyStateValueGlossary,
  getGlossaryTags,
  setDifficultWordsToShow,
  setDifficultWords,
  appMessageHandling,
  refreshSession,
  searchGlossery,
  ownExplanations,
  searchAIGlossery,
  premiumFeatureAlertWord,
  newEntryWord,
  wordText,
  enterTags,
  filterTagText,
  explanationWord,
  enterWord,
  userDetails,
  AIUnauthWord,
  showAiSearchUnauthAlert,
  glossaryForWord,
  editExplanationForWord,
  updateGlossaryEntry,
  deleteConfirm1Word,
  deleteConfirm2Word,
  noCancelWord,
  deleteGlossary,
  yesDeleteWord,
  env = 'web',
  copiedText,
  copyText,
  switchValue,
}: GlossaryFieldProps) => {
  const noWordMarked: DifficultWords[] = useMemo(
    () => [
      {
        word: noTextSelectedWord,
        original_words: '',
        explanation: '',
        source: 'na',
        copyRestriction: null,
        tags: null,
        linktosource: '',
        licensename: null,
        licenselink: null,
        id: '',
      },
    ],
    [noTextSelectedWord]
  );
  const [showAiSearchInputWarning, setShowAiSearchInputWarning] =
    useState(false);
  const [glossarInput, setGlossarInput] = useState('');
  const [explanationTextEdited, setExplanationText] = useState({});
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState({});
  const handleTagChange = (
    event: SelectChangeEvent<typeof glossaryTags> | any
  ) => {
    const {
      target: { value },
    } = event;
    setSelectedTagsList(value);
  };

  useEffect(() => {
    emptyStateValueGlossary({
      glossaryItems: { difficultWords: [] },
      highLightWord: {},
      AIWordExplanation: [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // menu style
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  // methods
  const getFullText = (searchGlosseryReq = true) => {
    let outputText = document.getElementById('translation-output');
    let inputText = document.getElementById('translation-input');

    if (!isEmpty(outputText?.innerText) || !isEmpty(inputText?.innerText)) {
      getExplanations(
        outputText?.innerText + ' ' + inputText?.innerText,
        searchGlosseryReq
      );
    }
    setEditMode({});
    setExplanationText({});
  };

  const fetchTags = async () => {
    getGlossaryTags();
  };

  useEffect(() => {
    //give space to animation to apply then make the check
    const timeoutId = setTimeout(() => fetchTags(), 200);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      // only use input / output textarea text if nothing is in input field
      if (isEmpty(glossarInput) && env !== 'wordaddin') {
        getFullText();
      }
    }, 400);

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [glossarInput]);

  useEffect(() => {
    setDifficultWordsToShow(difficultWords);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [difficultWords]);

  const getGlossaryFromInputField = (value: string) => {
    getExplanations(value);
    setShowAiSearchInputWarning(false);
    generateAiExplanations(value);
    return;
  };

  const getExplanations = async (
    fullText: string,
    searchGlosseryReq = true
  ) => {
    let diffWords = noWordMarked;

    if (!fullText || fullText === '') {
      setDifficultWords(diffWords);
      emptyStateValueGlossary({
        glossaryItems: { difficultWords: [] },
        highLightWord: {},
      });
      return;
    }

    // check if too many characters (10k)
    if (fullText?.length > 10000) {
      appMessageHandling();
      return;
    }

    const jsonData = {
      inputText: fullText,
      email: supabaseUser?.email ?? '',
    };

    refreshSession();
    if (!isEmpty(fullText) && searchGlosseryReq) {
      await searchGlossery(jsonData);
    }
  };

  useEffect(() => {
    if (glosseryItems) {
      ownExplanations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [glosseryItems]);

  const generateAiExplanations = async (value: string) => {
    if (!isEmpty(value)) {
      if (value.split(' ')?.length > 3) {
        setShowAiSearchInputWarning(true);
        return;
      }
      // use word in input field and call API from backend
      // API endpoint: glossary/ai/search

      // todo: loading state
      // call api to transform Input original text
      const jsonData = {
        word: value,
        email: supabaseUser?.email ?? '',
      };
      refreshSession();
      searchAIGlossery(jsonData);
    } else {
      emptyStateValueGlossary({ AIWordExplanation: [] });
    }
  };

  const isTagSelected = (tag: any) => {
    return selectedTagsList?.includes(tag) ? true : false;
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderAIExplanations = () => {
    if (showAiSearchInputWarning) {
      return (
        <Grid
          item
          xs={12}
          sx={{ pt: '6px !important' }}
        >
          <Box className="text-left">
            <p className={`mb-1 ${styles['glossarysubHead']}`}>
              {aiGeneratedWord}
            </p>
            <GlossaryItemAlert message={maxAILimitWord}></GlossaryItemAlert>
          </Box>
        </Grid>
      );
    } else {
      if (AIWordExplanation && AIWordExplanation?.length > 0 && !loading) {
        return (
          <Grid
            item
            xs={12}
            sx={{ pt: '6px !important' }}
          >
            <Box className="text-left">
              <p
                className={`${styles['glossarysubHead']}`}
                style={{ marginTop: 0, marginBottom: 0 }}
              >
                {aiGeneratedWord}
              </p>
              {AIWordExplanation.map(function (wordEntry: any, i) {
                return (
                  <GeneralGlossaryListItem
                    key={wordEntry.id + i}
                    wordEntry={wordEntry}
                    showAddIcon={true}
                    firstItem={i === 0 && AIWordExplanation.length > 1}
                    lastItem={
                      i === AIWordExplanation.length - 1 &&
                      AIWordExplanation.length > 1
                    }
                    onlyItem={AIWordExplanation.length === 1}
                    addGlossaryWord={addGlossaryWord}
                    supabaseUser={supabaseUser}
                    createNewGlossary={createNewGlossary}
                    copiedText={copiedText}
                    copyText={copyText}
                  />
                );
              })}
            </Box>
          </Grid>
        );
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid
        item
        container
        xs={12}
        spacing={2}
      >
        <GlossaryInputField
          loadingGlossary={loading || false}
          getGlossaryFromInputField={getGlossaryFromInputField}
          setGlossarInput={setGlossarInput}
          searchGlossaryWord={searchGlossaryWord}
          searchWord={searchWord}
        />

        {unAuthAlert && (
          <Grid
            item
            xs={12}
          >
            <GlossaryAlerts
              AIUnauthWord={AIUnauthWord}
              showAiSearchUnauthAlert={showAiSearchUnauthAlert}
            />
          </Grid>
        )}
        <Grid
          item
          xs={12}
        >
          <FormControl
            sx={{
              width: '100%',
              textAlign: 'left',
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: `${theme.palette.primary.main} !important`,
              },
              '& .MuiInputLabel-root': {
                color: `${theme.palette.primary.main} !important`,
              },
            }}
          >
            <InputLabel
              id="tag-filter-checkbox-label"
              size="small"
            >
              {filterTagText}
            </InputLabel>
            <Select
              labelId="glossary-tags-label"
              id="glossary-tags"
              aria-label="glossary tags"
              multiple
              value={selectedTagsList || []}
              onChange={handleTagChange}
              input={<OutlinedInput label={filterTagText} />}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
              size="small"
              open={open}
              onOpen={handleOpen}
              onClose={handleClose}
            >
              {glossaryTags?.map((tag, i) => (
                <MenuItem
                  key={tag + i}
                  value={tag}
                  sx={{
                    wordWrap: 'break-word',
                    whiteSpace: 'normal',
                    '&.Mui-selected': {
                      backgroundColor: `${theme.palette.secondary.main} !important`,
                    },
                  }}
                >
                  <Checkbox
                    checked={isTagSelected(tag)}
                    sx={{
                      '& .MuiSvgIcon-root': {
                        fill: theme.palette.primary.main,
                      },
                    }}
                  />
                  <ListItemText primary={tag} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <AddNewGlossary
            fetchTags={fetchTags}
            getFullText={getFullText}
            premiumFeatureAlertWord={premiumFeatureAlertWord}
            newEntryWord={newEntryWord}
            wordText={wordText}
            enterTags={enterTags}
            explanationWord={explanationWord}
            enterWord={enterWord}
            userDetails={userDetails}
            createNewGlossary={createNewGlossary}
            supabaseUser={supabaseUser}
          />
        </Grid>
        {difficultWordsToShow &&
          difficultWordsToShow.length > 0 &&
          !loading && (
            <Grid
              item
              xs={12}
            >
              <Box className="text-left">
                <div className={styles['headerBox']}>
                  <p className={`mb-0 ${styles['glossarysubHead']}`}>
                    {myGlossaryWord}
                  </p>
                  {exportLinkWord}
                </div>

                {difficultWordsToShow?.map(function (
                  wordEntry: any,
                  i: number
                ) {
                  return (
                    <OwnGlossaryListItem
                      key={i + wordEntry.id}
                      wordEntry={wordEntry}
                      getFullText={getFullText}
                      editMode={editMode}
                      setEditMode={setEditMode}
                      explanationTextEdited={explanationTextEdited}
                      setExplanationText={setExplanationText}
                      firstItem={i === 0 && difficultWordsToShow.length > 1}
                      lastItem={
                        i === difficultWordsToShow.length - 1 &&
                        difficultWordsToShow.length > 1
                      }
                      onlyItem={difficultWordsToShow.length === 1}
                      glossaryForWord={glossaryForWord}
                      editExplanationForWord={editExplanationForWord}
                      updateGlossaryEntry={updateGlossaryEntry}
                      deleteConfirm1Word={deleteConfirm1Word}
                      deleteConfirm2Word={deleteConfirm2Word}
                      noCancelWord={noCancelWord}
                      deleteGlossary={deleteGlossary}
                      yesDeleteWord={yesDeleteWord}
                      copiedText={copiedText}
                      copyText={copyText}
                      switchValue={switchValue}
                    />
                  );
                })}
              </Box>
            </Grid>
          )}
        {otherWordExplanation &&
          Object.keys(otherWordExplanation)?.length > 0 && (
            <Grid
              item
              xs={12}
              sx={{ pt: '0px !important', pb: '6px' }}
            >
              {/* Show Tag Select Menu */}
              {!('na' in otherWordExplanation) && <></>}

              {loading && (
                <div className="text-center ">
                  <CircularProgress color="primary" />
                </div>
              )}

              {Object.keys(otherWordExplanation)?.length > 0 && !loading && (
                <>
                  {/* first loop through own list of explanation words and show them */}
                  {Object.keys(otherWordExplanation).map(function (
                    value: any,
                    k
                  ) {
                    return (
                      <Box
                        className="text-left"
                        key={value + k}
                      >
                        {value !== 'na' && (
                          <p
                            className={`mb-0 mt-2 ${styles['glossarysubHead']}`}
                          >
                            {fromWord} {otherWordExplanation[value][0].source}
                          </p>
                        )}
                        {otherWordExplanation[value]?.map(function (
                          wordEntry: any,
                          i: number
                        ) {
                          return (
                            <GeneralGlossaryListItem
                              wordEntry={wordEntry}
                              key={i + wordEntry.id}
                              addGlossaryWord={addGlossaryWord}
                              supabaseUser={supabaseUser}
                              createNewGlossary={createNewGlossary}
                              firstItem={
                                i === 0 &&
                                otherWordExplanation[value].length > 1
                              }
                              lastItem={
                                i === otherWordExplanation[value].length - 1 &&
                                otherWordExplanation[value].length > 1
                              }
                              onlyItem={
                                otherWordExplanation[value].length === 1
                              }
                              copiedText={copiedText}
                              copyText={copyText}
                            />
                          );
                        })}
                      </Box>
                    );
                  })}
                </>
              )}
            </Grid>
          )}
        {renderAIExplanations()}
      </Grid>
    </ThemeProvider>
  );
};

export default GlossaryField;
